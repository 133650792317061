import { reportError } from '~/services/errors'
import { getUserToken, supabase } from '~/services/supabase'
import { ContactEntry } from '~/types/account/contacts'
import { CreateContactPayload, DeleteContactPayload, GetContactIdPayload, GetContactIdResponse } from '~/types/database/contacts'
import { Database } from '~/types/supabase'

const getContactId = async (payload: GetContactIdPayload) => {
  const token = await getUserToken()
  const data = await fetch('/api/collaborator/getId', {
    method: 'POST',
    headers: {
      'X-SUPABASE-TOKEN': token
    },
    body: JSON.stringify(payload)
  })

  const result: GetContactIdResponse = await data.json()

  if (result && 'error' in result) {
    const error = new Error(result.error.message)
    reportError(error)
    throw error
  }
  return result
}

const createContact = async (payload: CreateContactPayload) => {
  const row: Database['public']['Tables']['contacts']['Insert'] = {
    contact_id: payload.contactId,
  }
  const { data, error } = await supabase
    .from('contacts')
    .insert([row])
    .select()
  if (error) {
    reportError(error)
    throw error
  }
  return data
}

const deleteContact = async (payload: DeleteContactPayload) => {
  const user = (await supabase.auth.getUser()).data.user
  if(user){
    const { data, error } = await supabase
      .from('contacts')
      .delete()
      .eq('user_id', user.id)
      .eq('contact_id', payload.contactId)
    if (error) {
      reportError(error)
      throw error
    }
    return data
  }
}

const getContacts = async () => {
  const { data, error } = await supabase
    .from('contacts')
    .select('*, meta:users!contacts_contact_id_fkey(*)')
  if (error) {
    reportError(error)
    throw error
  }
  const formattedData: ContactEntry[] = data.map(entry => ({
    id: entry.user_id,
    contact_id: entry.contact_id,
    user_id: entry.user_id,
    email: entry.meta?.email as string,
    preferred_role: entry.meta?.preferred_role as string,
    pseudo: entry.meta?.pseudo as string,
    first_name: entry.meta?.first_name,
    last_name: entry.meta?.invite_source_series
  }))
  return formattedData
}

export {
  getContactId,
  createContact,
  deleteContact,
  getContacts
}